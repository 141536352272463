import React from 'react'

import {Typography, AppBar, Toolbar, Button, IconButton} from '@mui/material'
import useStyles from './styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

function AppHeader({text, onBack, position = "relative"}) {
    const classes = useStyles();

    if(onBack == null)
    {
        return (
            <AppBar position={position}>
                <Toolbar>              
                    <Typography variant = "h6">{text}</Typography>
                </Toolbar>
            </AppBar>
        )
    }
    else
    {
        return (
            <AppBar position={position} >
                <Toolbar>                
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick = {onBack}
                >
                    <ArrowBackIosNewIcon />
                </IconButton>
                    <Typography variant = "h6">{text}</Typography>
                </Toolbar>
            </AppBar>
        )
    }    
}

export default AppHeader
