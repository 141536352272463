import React from 'react'
import {Container, Typography} from '@mui/material'

import LikertAnswers from './LikertAnswers'
import useStyles from './styles'

function LikertQuestion({id, question, anchors, orientation, onValueChanged}) {
    const classes = useStyles();
    
    return (
        <Container align="center" className={classes.question}>
            <Typography variant = "h6" align="left" gutterBottom >{question}</Typography>
            <LikertAnswers anchors = {anchors} id={id} onValueChanged={onValueChanged} orientation={orientation}/>
        </Container>
    )
}

export default LikertQuestion
