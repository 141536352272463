import React from 'react'
import {TextField, Container, Typography} from '@mui/material'

import useStyles from './styles'

function OpenQuestion({id, question, short, label, onValueChanged}) {
    const classes = useStyles();
    const handleValueChange = (event)=>{
        onValueChanged(id, EscapeString(event.target.value), EscapeString(event.target.value));
    }
    return (
        <Container align="center" className={classes.question}>
            {question.length>0?<Typography variant = "h6" align="left" gutterBottom >{question}</Typography>:null}
            {short ?
                <TextField
                    onChange={handleValueChange}
                    variant = "outlined" 
                    label={label} 
                    size= "small"
                    fullWidth
                    singleline = "true"
                    />
                :
                <TextField                 
                    onChange={handleValueChange}
                    variant = "outlined" 
                    label={label}
                    size= "large"
                    multiline
                    minRows = {3}
                    fullWidth                    
                    />
            }
            
        </Container>
    )
}

export default OpenQuestion


function EscapeString(str)
{
    return JSON.stringify(str).slice(1,-1);
}