import {useState, useEffect} from 'react'
import {CssBaseline, Typography} from '@mui/material/';
import {createTheme, ThemeProvider} from '@mui/material/styles';

import AppHeader from './components/AppHeader';
import LoginPage from './components/LoginPage'
import Chestionar from './components/Chestionar'
import TermsOfService from './components/TermsOfService'
import AcceptNotifications from './components/AcceptNotifications'
import HomePage from './components/HomePage'
import Journal from './components/Journal';
import ChartPage from './components/ChartPage';
import ComeAgainPage from './components/ComeAgainPage';
import GoalsPage from './components/GoalsPage';
import OneSignal from 'react-onesignal';

import {APILogin, APIGetNextStep, APIAnswer, APIGetGoals, APIGetCharts, APIGetJurnal, APIGetCovid, APIPostJurnal, APIPostCovid, APILogEvent} from './logic/API';
import Colors from './components/Colors';

const theme = createTheme();

function App() {
  const [surveyAnswers, setSurveyAnswers] = useState([])
  const [error, setError] = useState("")
  const [page, setPage] = useState(()=>{
    if(!IsStored("code")) return "login";
    if(!IsStored("tos")) return "tos";

    return "loading";     
  });
  const [code, setCode] = useState(localStorage.getItem("code"))
  const [session, setSession] = useState([])
  const [surveyData, setSurvey] = useState([])  
  const [pageIndex, setSurveyPage] = useState([-1])
  const [myGoals, setGoals] = useState([]);
  const [myCharts, setCharts] = useState([]);
  const [myJurnal, setJurnal] = useState([]);
  const [myCovid, setCovid] = useState([]);

  const [showedCompletionWarning, setCompletionWarning] = useState(false)

  useEffect(()=>{
    OneSignal.init({
      appId:"5f5b22e9-ce8b-4af2-9b57-94b535b0eddb"
    });
  },[])

  useEffect(()=>{
    localStorage.setItem("code", code);
  }, [code])
  useEffect(async ()=>{    
    if(page == "loading") await NextStepFlow(session)
    window.scrollTo(0, 0)
    await LogEventFlow(page)
  }, [page])
  
  useEffect(()=>{
    if(JSON.stringify(surveyData) != "[]")
    {
      setSurveyAnswers([]);
      setSurveyPage(0);
    }
    
  }, [surveyData])

  useEffect(()=>{
    
    if(pageIndex >= 0 && JSON.stringify(surveyData) != "[]")
    {
      if(surveyData.id == "profile") setPage("chestionar");      
      else if(surveyData.id == "notifications") setPage("set_notifications");    
      else if(surveyData.id == "") setPage("home");
      else if(surveyData.id != null) setPage("chestionar");    
    }    
    window.scrollTo(0, 0)
  }, [pageIndex])

  const onLoginClicked = async (c) =>{
    const tmpSession = await LoginFlow(c);
    if(tmpSession !== "") setPage("tos");
  }

  const onAcceptTOS = () => {
    localStorage.setItem("tos", "TRUE");
    setPage("loading")
  }

  const onAnswer = (result) => {    
    let tempAnswers = surveyAnswers;
    for(let i = 0; i<result.length; i++) tempAnswers.push(result[i]);
    setSurveyAnswers(tempAnswers);    

    let pIndex = pageIndex;
    
    while(pIndex <= surveyData.pages.length - 1)
    {
      pIndex++;
      if(pIndex == surveyData.pages.length) break;

      if('condition' in surveyData.pages[pIndex])
      {
        let hasAnswer = false;
        for(let i = 0; i<tempAnswers.length; i++)
        {
          if(tempAnswers[i].id == surveyData.pages[pIndex].condition.qid)
          {
            for(let l = 0; l<surveyData.pages[pIndex].condition.answers.length; l++)
            {
              if(tempAnswers[i].value == surveyData.pages[pIndex].condition.answers[l])
              {
                hasAnswer = true;
                break;
              }
            }
          }
          if(hasAnswer) break;
        }
        
        if(hasAnswer)
        {
          setSurveyPage(pIndex)
          break;
        }
      }
      else
      {        
        setSurveyPage(pIndex)
        break;
      }      
    } 
    if(pIndex >= surveyData.pages.length) AnswerSurveyFlow(session, surveyData.id, tempAnswers);
  }

  const onInputJournal = (title, text) =>{
    AnswerJurnalFlow(session, title, text)
  }
  const onInputCovid = (title, text) =>{
    AnswerCovidFlow(session, title, text)
  }

  const onContinueNotifications = () =>
  {
    setPage("chestionar")
  }

  const onGoal = () =>
  {
    setPage("goal")
  }

  const onProgress = () =>{
    setPage("progress")
  }
  
  const onJournal = () =>{
    setPage("journal")
  }

  const onCovid = () =>{
    setPage("covid")
  }  

let msgTomorrow = "Poți completa chestionarul despre parcursul tău zilnic doar în intervalul orar 17.00-21.00. Nu uita, ai nevoie de minim 12 completări pentru a participa la tombolă."
let msgThankYou = "Nu uita, ai nevoie de minim 12 completări pentru a participa la tombolă."

if(code !== null && code !== "" && code.length > 5)
{
  msgTomorrow = "Poți completa chestionarul despre parcursul tău zilnic doar în intervalul orar 17.00-21.00. Nu uita, ai nevoie de minim 12 completări în aplicație și cele 2 chestionare din Google Forms pentru a primi 20 de ore de practică."
  msgThankYou = "Nu uita, ai nevoie de minim 12 completări în aplicație și cele 2 chestionare din Google Forms pentru a primi 20 de ore de practică."
}

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />      
      {
      page === "login" ? <><LoginPage onClick = {onLoginClicked} error = {error} /></> : 
      page === "chestionar" ? <><AppHeader text="Chestionar" position="fixed"/><Chestionar questions={surveyData.pages[pageIndex].questions} onAnswer={onAnswer} prevAnswers={surveyAnswers} goals={myGoals}/></> : 
      page === "tos" ? <><AppHeader text="Formular de Consimțământ informat" position="fixed"/><TermsOfService onAccept={onAcceptTOS} /></> : 
      page === "loading" ? <><AppHeader text="Loading"/></> :       
      page === "set_notifications" ? <><AppHeader text="My Goals"/><AcceptNotifications onContinue={onContinueNotifications} /></> : 
      page === "home" ? <><HomePage onGoals = {onGoal} onJournal = {onJournal} onProgress = {onProgress} onCovid = {onCovid} /></> : 
      page === "goal" ? <><AppHeader color={Colors.littleBoyBlue} text="Scopul meu" onBack={NextStepFlow} /><GoalsPage goals={myGoals} /></> : 
      page === "progress" ? <><AppHeader color={Colors.lightSalmon} text="Progresul tău" onBack={NextStepFlow} position="relative" /><ChartPage data={myCharts}/></> : 
      page === "journal" ? <><AppHeader color={Colors.englishViolet} text="Jurnal" position="relative" onBack={NextStepFlow}/><Journal data= {myJurnal} onAddEntry ={onInputJournal} instructions="Folosește această pagină ca un jurnal personal legat de scopul tău: \nCum ți-ai urmărit astăzi scopul?\nCe provocări ai întâlnit?\nCum ai trecut peste ele?\nCum te-ai simțit?" type={0} /></> : 
      page === "covid" ? <><AppHeader color={Colors.corn} text="Impact COVID-19" position="relative" onBack={NextStepFlow}/><Journal data= {myCovid} onAddEntry ={onInputCovid} instructions="Aici poți să îți notezi zilnic momente, experiențe, gânduri sau emoții legate de pandemia COVID-19." type={1} /></> : 
      page === "study_ended" ?  <><AppHeader text="My Goals" position="fixed" onBack={NextStepFlow}/><ComeAgainPage header="Studiul s-a încheiat" body="Perioada de colectare de date s-a încheiat. Dacă dorești poți continua să utilizezi aplicația pentru jurnal." onContinue={NextStepFlow} /></>: 
      page === "come_tomorrow" ? <><AppHeader text="My Goals" position="fixed" onBack={NextStepFlow}/><ComeAgainPage header="Te rugăm să revii în intervalul orar 17.00-21.00" body={msgTomorrow} onContinue={NextStepFlow} /></>: 
      page === "thank_you" ? <><AppHeader text="My Goals" position="fixed" onBack={NextStepFlow}/><ComeAgainPage header="Mulțumim! Te așteptăm să completezi și mâine în intervalul 17.00-21.00." body={msgThankYou} onContinue={NextStepFlow} /></>: 
      null
      }
    
    </ThemeProvider>);

  async function LoginFlow(c)
  {
    let tempSession = await APILogin(c);    
    if(tempSession !== "")
    {      
      setCode(c);
      setSession(tempSession);      
      setError("");

      let goals = await APIGetGoals(tempSession);
      let charts = await APIGetCharts(tempSession);
      let jurnal = await APIGetJurnal(tempSession);
      let covid = await APIGetCovid(tempSession);

      if(goals != "") setGoals(goals);
      if(charts != "") setCharts(charts);
      if(jurnal != "") setJurnal(jurnal)
      if(covid != "") setCovid(covid);
    }
    else
    {      
      setCode(null);
      setError("Codul nu există");
    }
    return tempSession;
  }

  async function NextStepFlow(session)
  {
    if (!IsStored("tos")) setPage("tos");
    else
    {
        let nextStep = await APIGetNextStep(session);
        if(nextStep.error == "")
        {
          setSurvey(nextStep.payload);
        }
        else if(nextStep.error == "no action available")
        {
          console.log(JSON.stringify(nextStep))
          if(!showedCompletionWarning)
          {
            if(nextStep.payload.survey_index > 14) setPage("study_ended")
            else setPage("come_tomorrow")
            setCompletionWarning(true)
          }
          else setPage("home")           
        }
        else if(nextStep.error =="thank you" || nextStep.error=="thank_you")
        {
          if(!showedCompletionWarning)
          {
            setPage("thank_you")
            setCompletionWarning(true)
          }
          else setPage("home")       
        }
        else if(nextStep.error == "session expired") return await NextStepFlow(await LoginFlow(code));
        else 
        {
          alert("error:"+nextStep.error);
        }
    }
  }

  async function LogEventFlow(pg)
  {
    await APILogEvent(code, pg, "");    
  }

  async function AnswerSurveyFlow(session, surveyID, answers)
  {
    let formAnswers = new FormData();
    for(let i = 0; i<answers.length; i++)
    {
      if(answers[i].type != "Info") formAnswers.append(answers[i].id, answers[i].value);
    }    

    let response = await APIAnswer(session, surveyID, formAnswers)    
    if(response.error == "")
    {
      setGoals(await APIGetGoals(session))
      setCharts(await APIGetCharts(session))
      setJurnal(await APIGetJurnal(session))
      setCovid(await APIGetCovid(session))
      await NextStepFlow(session);
    }
    else if(response.error == "session expired")
    {
      return await APIAnswer(await LoginFlow(code), surveyID, answers);
    }
    else alert("error:"+response.error);
  }

  async function AnswerJurnalFlow(session, title, text)
  {    

    let formAnswers = new FormData();
    formAnswers.append("title", title);
    formAnswers.append("text", text);    

    let response = await APIPostJurnal(session, formAnswers)    
    if(response.error == "")
    {      
      setJurnal(await APIGetJurnal(session))            
    }
    else if(response.error == "session expired")
    {
      return await AnswerJurnalFlow(await LoginFlow(code), title, text);
    }
    else alert("error:"+response.error);
  }

  async function AnswerCovidFlow(session, title, text)
  {
    let formAnswers = new FormData();
    formAnswers.append("title", title);
    formAnswers.append("text", text);    

    let response = await APIPostCovid(session, formAnswers)    
    if(response.error == "")
    {      
      setCovid(await APIGetCovid(session))            
    }
    else if(response.error == "session expired")
    {
      return await AnswerCovidFlow(await LoginFlow(code), title, text);
    }
    else alert("error:"+response.error);
  }
}

export default App;

function IsStored(key)
{
  if(localStorage.getItem(key) == null) return false;
  if(localStorage.getItem(key) == "" || localStorage.getItem(key) == "null") return false;

  return true;
}