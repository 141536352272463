import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';

function ChartPage({data}) {
    

    return (
        <div>
            <ResponsiveContainer width="90%" height={400}>
            <LineChart data={data} margin={{ top: 25, right: 20, bottom: 5, left: 0 }}>
                    <Line type="monotone" dataKey="Progres" stroke="#8884d8" strokeWidth={3} />
                    <Line type="monotone" dataKey="Îngrijorări" stroke="#ff0000" strokeWidth={3} />
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <XAxis dataKey="name" />
                    <YAxis dataKey=""/>
                    <Tooltip />
                    <Legend />
            </LineChart>
            </ResponsiveContainer>         
        </div>
    )
}

export default ChartPage  