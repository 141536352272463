import React from 'react'
import GoalCard from './GoalCard'

function GoalsPage({goals}) {
    return (
        <div>
            {                
                goals.slice(0).reverse().map(g => (<GoalCard goal = {g.goal} status={g.status} domain={g.domain} setTime={timeConverter(g.created)} completeTime={timeConverter(g.end)}  />))
            }
        </div>
    )
}

function timeConverter(UNIX_timestamp){
    if(UNIX_timestamp === "0") return "";

    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Ian','Feb','Mar','Apr','Mai','Iun','Iul','Aug','Sep','Oct','Noi','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year;
    return time;
  }

export default GoalsPage
