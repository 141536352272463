const Colors = {
    white : "#FFF",
    darkText : "#222",
    lightSalmon: "#FCA17D",
    englishViolet: "#44344F",
    littleBoyBlue:"#71A9F7",
    corn: "#FFF275",
    blush:"#DA627D"    
}

export default Colors;