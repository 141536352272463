import {React, useState, useLayoutEffect} from 'react'
import {Typography, Grid} from '@mui/material'
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import ShareTwoToneIcon from '@mui/icons-material/ShareTwoTone';
import OfflinePinTwoToneIcon from '@mui/icons-material/OfflinePinTwoTone';
import TrackChangesTwoToneIcon from '@mui/icons-material/TrackChangesTwoTone';
import CoronavirusTwoToneIcon from '@mui/icons-material/CoronavirusTwoTone';

import useStyles from './styles';

function useWindowSize() {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

function TileButton({label, bgColor, textColor, iconName, onClicked}) {
    const classes = useStyles();
    const size = useWindowSize();

    const cardStyle = 
    {
        height: (size[0] < 1200) ? '50vh' : '100vh',
        backgroundColor : bgColor
    }

    const iconStyle =
    {
        height:'100%',
        width:'100%',
        paddingLeft:'16%',
        paddingRight:'16%',
        color : textColor, 
        fontSize:100, 
        margin:'auto'
    }

    const textStyle =
    {
        marginTop: '-18%',
        fontSize:'3vh',
        color : textColor
    }

    return (
        <Grid item 
            xs = {6}
            md = {6} 
            lg={3}        
            style = {cardStyle}  
            onClick = {onClicked}          
            >            
            {
                iconName === "Progress" ? <TrackChangesTwoToneIcon sx={iconStyle} /> : 
                iconName === "Goals" ? <OfflinePinTwoToneIcon sx={iconStyle} /> : 
                iconName === "Journal" ? <MenuBookTwoToneIcon sx={iconStyle} /> : 
                iconName === "Social" ? <ShareTwoToneIcon sx={iconStyle} /> : 
                iconName === "Covid" ? <CoronavirusTwoToneIcon sx={iconStyle} /> : 
                <MenuBookTwoToneIcon sx={iconStyle} />
            }
            <Typography 
                align="center"                                 
                fontWeight="fontWeightBold"
                sx={textStyle}
            >{label}</Typography>
        </Grid>
    )
}

export default TileButton
