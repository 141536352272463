import React from 'react'
import {ToggleButtonGroup, ToggleButton} from '@mui/material'


function LikertAnswers({id, anchors, onValueChanged, orientation="horizontal"}) {

    const [selectedValue, updateLikert] = React.useState(-1);
    
    const handleClick = (event, newValue) => {
        updateLikert(newValue)
        
        if(newValue !=="" && newValue != null) onValueChanged(id, newValue.split(":")[0], newValue.split(":")[1])
    }

    return (
        <ToggleButtonGroup orientation={orientation} fullWidth={true} size="small" color = "primary" value = {selectedValue} onChange= {handleClick} exclusive >
           {anchors.map(a => (
           <ToggleButton
            size="small" 
            key={a.value} 
            value={a.value+":"+a.label} 
            sx={{fontSize:"0.6em", paddingLeft:"0.4em", paddingRight:"0.4em"}}>
                {a.label}
            </ToggleButton>))
            }
        </ToggleButtonGroup>
    )
}

export default LikertAnswers
