import React from 'react'
import JournalCard from './JournalCard'
import JournalInputCard from './JournalInputCard';
import {Container, Typography, Button, Grid} from '@mui/material'

function Journal({data, onAddEntry, instructions, type}) {

    const [withInput, setInput] = React.useState(false);

    function handleAdd()
    {
        setInput(true)
    }
    function handleSave(title, text)
    {
        onAddEntry(title, text)
        setInput(false)
    }
    if(data.length == 0)
    {
        return (
            <Container maxWidth="sm" sx={{mt:3}}>
                {
                    instructions.split('\\n').map(row=>(
                        <Typography variant="h6" align="left" key={row} color="textSecondary" >                    
                            {row}                    
                        </Typography>
                    ))
                }
                
                {withInput ? 
                    <JournalInputCard type={type} onAdded={handleSave}/> :
                    <Grid align = "center">
                        <Button variant="contained" color="primary" size="large" align="right" onClick={handleAdd}>Adaugă</Button>
                    </Grid>
                }                
            </Container>               
        )
    }
    else
    {
       return (
        <Container align="center" sx={{mt:3}}>
            {
                data.map(
                    j => (<JournalCard title={j.data.title} text={j.data.text} key ={j.id} type={0} time={timeConverter(j.time)}/>)
                )
            }
            {
                withInput ? 
                    <JournalInputCard type={type} onAdded={handleSave}/> :
                    <Grid align = "center">
                        <Button variant="contained" color="primary" size="large" align="right" onClick={handleAdd}>Adaugă</Button>
                    </Grid>
            }
        </Container>
       )
    }

}

export default Journal


function timeConverter(UNIX_timestamp){
    if(UNIX_timestamp === "0") return "";

    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Ian','Feb','Mar','Apr','Mai','Iun','Iul','Aug','Sep','Oct','Noi','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year;
    return time;
  }