import React from 'react'

import {Container, Typography, Button, Grid} from '@mui/material'
import useStyles from './styles';

function AcceptNotifications({onContinue}) {
    const classes = useStyles();

    return (
        <main>
          <div className = {classes.container}>
            <Container maxWidth="sm">
                <Typography variant="h2" align="center" color="textPrimary" gutterBottom> Notificări</Typography>
                <Typography variant="h6" align="center" color="textSecondary" paragraph> Te rugăm să accepți notificările pentru a te putea ajuta să îți urmezi scopul.</Typography>
               
                <Grid align = "center"><Button variant="contained" color="primary" size="large" align="right" onClick={onContinue}>OK</Button></Grid>
            </Container>
        </div>
      </main>
    )
}

export default AcceptNotifications
