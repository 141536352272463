import React from 'react'
import {useState} from 'react'
import {Slider, Box, Typography} from '@mui/material'

import useStyles from './styles'

function ScaleQuestion({anchors, question, onValueChanged, id}) {

    const [changed, setChanged] = useState(false)

    function valuetext(value) 
    {
        return value;
    }

    function handleChange(event, newValue)
    {
        setChanged(true)
        onValueChanged(id, newValue, newValue)
    }

    return (
        <Box sx={{ width: "100%"}}>
            <Typography variant = "h6" align="center" gutterBottom >{question}</Typography>
            <Slider
            key = {id}
            sx = {{width:"70%", ml:"15%"}}            
            onChange={handleChange}
            aria-label="Custom marks"
            getAriaValueText={valuetext}
            step={1}
            track={changed ? "normal" : false}
            defaultValue={(anchors[0].value + anchors[anchors.length - 1].value)/2}
            min = {anchors[0].value}
            max = {anchors[anchors.length - 1].value}
            valueLabelDisplay="auto"
            marks={anchors}
            />
        </Box>
    )
}

export default ScaleQuestion
