const apiPath = "https://goals.selfid.eu/api/";

export async function APIRegister (code)
{
    const res = await fetch(apiPath+"register/"+code);
    const data = await res.json();

    if(data.error === "") return data.session_token;
    else return "";
}

export async function APILogin(code)
{
    const res = await fetch(apiPath+"login/"+code);
    const data = await res.json();
    if(data.error === "") return data.payload.session_token;
    else return "";    
}

export async function APIGetUserData(session)
{
    const res = await fetch(apiPath+"user/"+session);
    const data = await res.json();

    if(data.error === "") return data.payload;
    else return null;
}

export async function APIGetNextStep(session)
{    
    console.log(apiPath+"next_step/"+session)
    const res = await fetch(apiPath+"next_step/"+session);
    const data = await res.json();    
    return data;
}

export async function APIAddGoal(session, goal)
{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ goal: goal })
    };

    const res = await fetch(apiPath+"add_goal/"+session, requestOptions);
    const data = await res.json();

    if(data.error === "") return data.payload;
    else return null;
}

export async function APIAnswer(session, surveyID, answers)
{
    const requestOptions = {
        method: "POST",
        body: answers
    };
    let url = apiPath+"answer/"+session+"/"+surveyID    
    console.log(url)
    const res = await fetch(url, requestOptions);    
    const data = await res.json();
    
    return data;
}

export async function APIGetGoals(session)
{
    console.log(apiPath+"get_goals/"+session)
    const res = await fetch(apiPath+"get_goals/"+session);    
    const data = await res.json();    
    if(data.error === "") return data.payload;
    else return "";
}

export async function APIGetCharts(session)
{
    console.log(apiPath+"get_chart/"+session)
    const res = await fetch(apiPath+"get_chart/"+session);    
    const data = await res.json();
    if(data.error === "") return data.payload;
    else return "";
}

export async function APIGetJurnal(session)
{
    console.log(apiPath+"get_jurnal/"+session)
    const res = await fetch(apiPath+"get_jurnal/"+session);    
    const data = await res.json();
    if(data.error === "") return data.payload;
    else return "";
}

export async function APIGetCovid(session)
{
    console.log(apiPath+"get_covid/"+session)
    const res = await fetch(apiPath+"get_covid/"+session);    
    const data = await res.json();
    if(data.error === "") return data.payload;
    else return "";
}

export async function APIPostJurnal(session, jurnal)
{
    const requestOptions = {
        method: "POST",
        body: jurnal
    };
    let url = apiPath+"post_jurnal/"+session+"/"    
    console.log(url)
    const res = await fetch(url, requestOptions);    
    const data = await res.json();
    
    return data;
}

export async function APIPostCovid(session, covid)
{
    const requestOptions = {
        method: "POST",
        body: covid
    };
    let url = apiPath+"post_covid/"+session+"/"    
    console.log(url)
    const res = await fetch(url, requestOptions);    
    const data = await res.json();
    
    return data;
}

export async function APILogEvent(session, evt, meta)
{
    let formAnswers = new FormData();
    formAnswers.append("event", evt);
    formAnswers.append("meta", meta);    

    const requestOptions = {
        method: "POST",
        body: formAnswers
    };
    let url = apiPath+"log_event/"+session+"/"    
    console.log(url)
    await fetch(url, requestOptions);       
}