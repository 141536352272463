import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import BlurLinearIcon from '@mui/icons-material/BlurLinear';

function GoalCard({goal, domain, status, setTime, completeTime}) {
    const theme = useTheme();
    
    return (
      <Card sx={{ display: 'flex', margin:2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography component="div" variant="h5">
             {goal}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" component="div">
              {setTime}
            </Typography>
          </CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
            {
            domain == 1 ? <><SchoolIcon/><Typography variant="subtitle1" color="text.secondary" sx={{ml:1, mr:1}}>Educație</Typography> </>  : 
            domain == 2 ? <><WorkIcon/><Typography variant="subtitle1" color="text.secondary" sx={{ml:1, mr:1}}>Dezvoltare profesională</Typography> </>  : 
            domain == 3 ? <><HealthAndSafetyIcon/> <Typography variant="subtitle1" color="text.secondary" sx={{ml:1, mr:1}}>Sănătate</Typography></>  : 
            domain == 4 ? <><EmojiPeopleIcon/> <Typography variant="subtitle1" color="text.secondary" sx={{ml:1, mr:1}}>Social</Typography></> :
            null
            }
            {
            status == 1 ? <><CheckCircleOutlineIcon color="success"/><Typography variant="subtitle1" color="text.secondary" sx={{ml:1, mr:1}}>{completeTime}</Typography> </>  : 
            status == 2 ? <><BlurLinearIcon color="primary"/><Typography variant="subtitle1" color="primary" sx={{ml:1, mr:1}}>Activ</Typography> </>  : 
            status == 3 ? <><DoDisturbAltOutlinedIcon color="warning"/> <Typography variant="subtitle1" color="warning" sx={{ml:1, mr:1}}>{completeTime}</Typography></>  : 
            status == 4 ? <><CancelOutlinedIcon color="error"/> <Typography variant="subtitle1" color="error" sx={{ml:1, mr:1}}>{completeTime}</Typography></> :
            null
            }              
          </Box>
          
        </Box>        
      </Card>
    );}

export default GoalCard
