import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import CoronavirusTwoToneIcon from '@mui/icons-material/CoronavirusTwoTone';

function JournalCard({title, text, time, type}) {
    const theme = useTheme();
    
    return (
      <Card sx={{ display: 'flex', margin:2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography component="div" variant="h5" align='left'>
             {title}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" component="div" align='left'>
            <pre style={{ fontFamily: 'inherit' }}>
                {text}
            </pre>
            </Typography>
          </CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
            {
            type == 0 ? <><MenuBookTwoToneIcon color="primary"/><Typography variant="subtitle1" color="text.secondary" sx={{ml:1, mr:1}}>{time}</Typography> </>  : 
            type == 1 ? <><CoronavirusTwoToneIcon color="primary"/> <Typography variant="subtitle1" color="text.secondary" sx={{ml:1, mr:1}}>{time}</Typography></>  :             
            null
            }                   
          </Box>
          
        </Box>        
      </Card>
    );}

export default JournalCard
