import React from 'react'
import {Typography, Container} from '@mui/material/'

import Login from './Login'
import useStyles from './styles'
import ReactLogo from '../logo.svg'

function LoginPage({onClick, error}) {

    const classes = useStyles();

    return (
        <>
      <main>
            <Container>
             <div className = {classes.container} style=
                {{
                  backgroundImage: `url(${ReactLogo})`,  
                  backgroundRepeat  : 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize:'cover',
                  resize:'both',
                  minHeight:'40vmin'
                }}></div>
                <Typography variant="h2" align="center" color="textPrimary" gutterBottom> My Goals</Typography>
              </Container>
              <Container sx=
                  {{
                    position:'absolute',
                    bottom:'1vh',
                    minWidth:'100vw'
                    
                  }}>                    
                  <Typography variant="h2" align="center" color="textPrimary" gutterBottom> Login</Typography>
                  <Typography variant="h6" align="center" color="textSecondary" paragraph> Te rugăm să introduci codul tău unic de participare</Typography>
                  <Login onClick = {onClick}/>
                  <Typography variant="h6" align="left" color="#ca1111" paragraph>{error}</Typography>                
                       
              </Container>
      </main>
    </>      
    )
}


export default LoginPage
