import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';

import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import CoronavirusTwoToneIcon from '@mui/icons-material/CoronavirusTwoTone';

import OpenQuestion from './OpenQuestion';

function JournalInputCard({type, onAdded}) {
    const theme = useTheme();
    
    const [title, setTitle] = React.useState("");
    const [text, setText] = React.useState("");

    function onTitleChanged(id, value, label)
    {
        setTitle(value)
    }

    function onTextChanged(id, value, label)
    {
        setText(value)
    }
    function handleAdd()
    {
        if (text.length > 1 && title.length > 1) onAdded(title,text)
    }

    return (
      <Card sx={{ display: 'flex', margin:1}}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width:'100%' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <OpenQuestion question="" label="Titlu" short={true} onValueChanged = {onTitleChanged} />
            <OpenQuestion question="" label="Text" short={false} onValueChanged = {onTextChanged} />
          </CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>           
            <Button onClick={handleAdd}>Salvează</Button>                  
          </Box>
          
        </Box>        
      </Card>
    );}

export default JournalInputCard
