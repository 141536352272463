import React from 'react';

import {Container, Typography, Button, Grid, Box} from '@mui/material';


import TileButton from './TileButton';
import useStyles from './styles';
import Colors from './Colors';

function HomePage({onProgress, onGoals, onJournal, onCovid}) {
    const classes = useStyles();

    return (
        <main>
          <div>            
            <Grid container spacing ={0}>
                <TileButton 
                    bgColor={Colors.blush} 
                    textColor= {Colors.white}
                    label="Progres"
                    iconName="Progress"
                    onClicked = {onProgress}
                />                
                <TileButton 
                    bgColor={Colors.littleBoyBlue} 
                    textColor= {Colors.white}
                    label="Scopul Meu"
                    iconName="Goals"
                    onClicked = {onGoals}
                />
                <TileButton 
                    bgColor={Colors.englishViolet} 
                    textColor= {Colors.white}
                    label="Jurnal"
                    iconName="Journal"
                    onClicked = {onJournal}
                />
                <TileButton 
                    bgColor={Colors.lightSalmon} 
                    textColor= {Colors.white}
                    label="Impact Covid"
                    iconName="Covid"
                    onClicked = {onCovid}
                />
            </Grid>
            
        </div>
      </main>
    )
}

export default HomePage
