import React from 'react'
import {Typography, TextField, Container} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

import useStyles from './styles'

function DateQuestion({id, question, onValueChanged}) {

  const classes = useStyles();
  const [value, setValue] = React.useState(new Date('2001-01-01T00:00:00'));

  const handleChange = (newValue) => {
      setValue(newValue);
      onValueChanged(id, newValue, newValue);
  };

  return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Typography variant = "h6" align="center" gutterBottom >{question}</Typography>
        <Container align="center" className={classes.question}>
          <DesktopDatePicker
            label={question}
            inputFormat="dd/MM/yyyy"
            value={value}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} />}
          />          
          </Container>
      </LocalizationProvider>
      );
}

export default DateQuestion
