import React from 'react'
import {useState} from 'react'
import {Grid, TextField, Button} from '@mui/material'


function Login({onClick}) {

    const [code, setCode] = useState([])

    return (
        <Grid container spacing = {3} direction = "row" justifyContent ="center" alignItems="center">
            <Grid item>
                <TextField                 
                    variant = "outlined" 
                    label="Codul tău" 
                    size="small"
                    onChange={(e)=>setCode(e.target.value)} />
            </Grid>
            <Grid item>
                <Button variant="contained" color="primary" onClick={()=>onClick(code)}>Login</Button>
            </Grid>            
        </Grid>
        
    )
}

export default Login
