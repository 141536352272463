import React from 'react'
import {Grid, Container, Typography, Button} from '@mui/material'

import useStyles from './styles'

function TermsOfService({onAccept}) {

    const classes = useStyles();

    return (
        <>
            <Container maxWidth="md" className={classes.container}>
                
                <Typography variant="p" align="justify" color="textSecondary" paragraph> Am citit și am înțeles toate informațiile care mi-au fost prezentate în Foaia de informare despre participarea mea la această cercetare. Am putut pune întrebări despre cercetare, cât și despre implicarea mea și am primit răspunsuri la aceste întrebări. Îmi dau consimțământul voluntar să particip în cercetare și știu că pot să mă retrag oricând, fără a trebui să motivez această decizie. Datele cu caracter personal vor fi colectate, înregistrate, procesatem organizate, stocate în deplină conformitate cu Regulamentul UE nr. 679 din 2016 („Regulamentul general privind protecția datelor”/"General Data Protection Regulation (GDPR)") intrat în vigoare în 25 mai 2018, precum și cu orice altă legislație aplicabilă pe teritoriul României.</Typography>
                <Typography variant="p" align="justify" color="textSecondary" paragraph> Am citit și am înțeles toate informațiile din Foaia de informare și din acest document.</Typography>
                
                <Button variant="contained" color="primary" size="large" onClick = {onAccept}>Accept</Button>
            </Container>
        </>
    )
}

export default TermsOfService
