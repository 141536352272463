import React from 'react'
import {Container, Typography, Button, DatePicker} from '@mui/material'

import LikertQuestion from './LikertQuestion'
import OpenQuestion from './OpenQuestion'
import DateQuestion from './DateQuestion'
import ScaleQuestion from './ScaleQuestion'

import useStyles from './styles'

function Chestionar({questions, onAnswer, prevAnswers, goals}) {
    const classes = useStyles();        

    let answers = questions.map(q=>{
        let container = {};        
        container.id = q.id;
        container.value = "";
        container.type = q.type;
        container.label = "";
        return container;
    });
    

    const handleAnswer = () =>
    {
      var found = false;
      for(let i = 0; i<answers.length; i++)
      {
        if(answers[i].value === "" && answers[i].type !== "Info") found = true;
      }
      
      if(!found) onAnswer(answers);
      else alert("Te rugăm să răspunzi la toate întrebările");
    }

    const handleValueChanged = (id, answer, label) =>
    {      
      for(let i = 0; i<answers.length; i++){
        if(answers[i].id == id)
        {
          if(answer == null){
            answer = "";
          } 
          answers[i].value = answer;
          answers[i].label = label;
          break;
        }
      }
    }    

    return (
        <main>
          <div className = {classes.container}>
            <Container maxWidth="sm">               
                {questions.map(q => {
                  let question = q.question

                  for(let i = 0; i < prevAnswers.length; i++)
                  {
                    question = question.replace("<"+prevAnswers[i].id+">", prevAnswers[i].label);
                  }
                  
                  if(goals.length > 0)question = question.replace("<last_goal>",goals[goals.length - 1].goal);

                  return (
                  q.type === "Info" ? <Typography key = {q.id} variant = "h5" align="left" gutterBottom >{question}</Typography> :
                  q.type === "Likert" ? <LikertQuestion key = {q.id} id={q.id} question={question} anchors={q.answers} orientation={q.orientation} onValueChanged={handleValueChanged}/> :
                  q.type === "Scale" ? <ScaleQuestion key = {q.id} id={q.id} question={question} anchors={q.answers} onValueChanged={handleValueChanged}/> :
                  q.type === "OpenShort" ? <OpenQuestion key = {q.id} id={q.id} question={question} label = {q.answers[0].label} short ={true} onValueChanged={handleValueChanged}/> :
                  q.type === "OpenLong" ? <OpenQuestion key = {q.id} id={q.id} question={question} label = {q.answers[0].label} short = {false} onValueChanged={handleValueChanged} /> :
                  q.type === "Date" ? <DateQuestion key = {q.id} id={q.id} question={question}  onValueChanged={handleValueChanged} /> :
                  null
                  )
                })}
                <Button variant="contained" color="primary" size="large" align="right" onClick={handleAnswer}>Continuă</Button>
            </Container>
        </div>
      </main>
    )
  }
  
  export default Chestionar
  //<Typography variant="h5" align="center" color="textSecondary" paragraph> Te rugăm să răspunzi la următoarele întrebări</Typography>