import {makeStyles} from '@mui/styles'

const useStyles = makeStyles((theme) => ({   
    container : {
        backgroundColor : theme.palette.background.paper,
        padding : theme.spacing(8, 0, 6)
    },
    question : {
        padding : theme.spacing(3, 0, 2)
    },
    root: {                
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      },

    centerImage :{
        display:'flex',
        alignItems:'center',
        flexWrap:'wrap'
    },
    
}));

export default useStyles;